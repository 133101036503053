import React from "react";
import { Typography, Paper, Divider, Box } from "@mui/material";
import Chart from "react-apexcharts";

function UserStatusChart({ userData }) {
  const chartOptions = {
    chart: { id: "user-status" },
    labels: ["Active", "Inactive"],
  };

  const series = [
    userData.filter((user) => user.status === "active").length,
    userData.filter((user) => user.status === "inactive").length,
  ];

  return (
    <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Typography variant="h2" component="h2" gutterBottom>
          User Status
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Chart options={chartOptions} series={series} type="pie" width="100%" />
      </Box>
    </Paper>
  );
}

export default UserStatusChart;
