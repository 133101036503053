import React from "react";
import { Typography, Paper, Grid, Divider, Box } from "@mui/material";
import Chart from "react-apexcharts";

function CourseStatusChart({ courseData }) {
  // Count courses by status
  const statusCounts = {
    draft: 0,
    published: 0,
    archived: 0,
  };

  // Count free vs paid courses
  let freeCourses = 0;
  let paidCourses = 0;

  courseData.forEach((course) => {
    statusCounts[course.status]++;
    course.isFree ? freeCourses++ : paidCourses++;
  });

  const statusChartOptions = {
    chart: { id: "course-status" },
    labels: ["Draft", "Published", "Archived"],
    colors: ["#FFA500", "#4CAF50", "#9E9E9E"],
  };

  const statusSeries = [
    statusCounts.draft,
    statusCounts.published,
    statusCounts.archived,
  ];

  const pricingChartOptions = {
    chart: { id: "course-pricing" },
    labels: ["Free", "Paid"],
    colors: ["#2196F3", "#FF5722"],
  };

  const pricingSeries = [freeCourses, paidCourses];

  return (
    <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Typography variant="h2" component="h2" gutterBottom>
          Course Analytics
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} m={"auto"}>
            <Typography
              variant="subtitle1"
              gutterBottom
              sx={{ fontWeight: "500" }}
            >
              Course Statistics
            </Typography>
            <Typography>Total Courses: {courseData.length}</Typography>
            <Typography>Draft Courses: {statusCounts.draft}</Typography>
            <Typography>Published Courses: {statusCounts.published}</Typography>
            <Typography>Archived Courses: {statusCounts.archived}</Typography>
            <Typography>Free Courses: {freeCourses}</Typography>
            <Typography>Paid Courses: {paidCourses}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500" }}
              gutterBottom
            >
              Course Status Distribution
            </Typography>
            <Chart
              options={statusChartOptions}
              series={statusSeries}
              type="pie"
              // width="100%"
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "500" }}
              gutterBottom
            >
              Free vs Paid Courses
            </Typography>
            <Chart
              options={pricingChartOptions}
              series={pricingSeries}
              type="pie"
              // width="100%"
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default CourseStatusChart;
