import React, { useEffect, useMemo, useState } from "react";
import {
  Typography,
  Box,
  Divider,
  Button,
  Chip,
  Grid,
  Paper,
  Backdrop,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import HistoryIcon from "@mui/icons-material/History";
import InfoIcon from "@mui/icons-material/Info";
import SchoolIcon from '@mui/icons-material/School';
import { Link, useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import MUITableComponent from "../../components/MUITableComponent";
import TextInputComponent from "../../components/TextInputComponent";
import { formatDateTimeToLongString, formatDateToLongString, getCurrentTimestamp, USER_STATUS_OPTIONS } from "../../utils/constant";
import SelectInputComponent from "../../components/SelectInputComponent";
import usersService from "../../services/usersService";

function ManageUsersPage() {
  const [usersList, setUsersList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [activeAccounts, setActiveAccounts] = useState(0);
  const [inactiveAccounts, setInactiveAccounts] = useState(0);
  const [search, setSearch] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const allOption = { label: "All", value: "all" };
  const [isLoading, setIsLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();

  const handleMenuOpen = (event, user) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleManage = () => {
    navigate(`/users/edit/${selectedUser.userId}`);
    handleMenuClose();
  };

  const handleUserActivity = () => {
    // Implement user activity functionality
    console.log("User activity for:", selectedUser);
    handleMenuClose();
  };

  const handleViewMoreDetails = () => {
    setOpenDialog(true);
    console.log(selectedUser);
    handleMenuClose();
  };

  const handleCourseEnrollment = () => {
    navigate(`/users/${selectedUser.userId}/course-enrollment`);
    handleMenuClose();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const columns = [
    { id: "name", label: "Name", sortable: true, width: "20%" },
    { id: "email", label: "Email ID", sortable: true, width: "25%" },
    {
      id: "facebookUserName",
      label: "Facebook Username",
      sortable: true,
      width: "20%",
    },
    {
      id: "createdAt",
      label: "Join Date",
      sortable: true,
      width: "15%",
      sortFunction: (a, b) =>
        new Date(b.createdAtRaw) - new Date(a.createdAtRaw),
      render: (row) => formatDateToLongString(row.createdAtRaw),
    },
    { id: "status", label: "Status", sortable: false, width: "10%" },
    {
      id: "action",
      label: "Action",
      sortable: false,
      width: "10%",
      render: (row) => (
        <IconButton onClick={(event) => handleMenuOpen(event, row)}>
          <MoreVertIcon />
        </IconButton>
      ),
    },
  ];

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const filteredUsers = useMemo(() => {
    return usersList.filter((user) => {
      const firstName = user.firstName?.toLowerCase() || "";
      const lastName = user.lastName?.toLowerCase() || "";
      const email = user.email?.toLowerCase() || "";
      const facebookUserName = user.facebookUserName?.toLowerCase() || "";
      const createdAt = user.createdAt?.toLowerCase() || "";
      const status = user.status?.toLowerCase() || "";
      const searchLower = search.toLowerCase();

      return (
        (firstName.includes(searchLower) ||
          lastName.includes(searchLower) ||
          email.includes(searchLower) ||
          facebookUserName.includes(searchLower) ||
          createdAt.includes(searchLower) ||
          status.includes(searchLower)) &&
        (statusFilter === "all" || status === statusFilter.toLowerCase())
      );
    });
  }, [usersList, search, statusFilter]);

  const getStatusColors = (status) => {
    switch (status) {
      case "active":
        return { bg: "#e6f7ed", text: "#1e4e31" };
      case "inactive":
        return { bg: "#fff4e5", text: "#663c00" };
      case "blocked":
        return { bg: "#fce8e8", text: "#621b18" };
      default:
        return { bg: "#f0f0f0", text: "#333333" };
    }
  };

  const tableData = filteredUsers.map((user) => {
    const statusColors = getStatusColors(user.status);
    return {
      name: `${user.firstName || ""} ${user.lastName || ""}`.trim(),
      email: user.email,
      facebookUserName: user.facebookUserName || "",
      createdAt: formatDateToLongString(user.createdAt),
      createdAtRaw: user.createdAt,
      userId: user.userId,
      lastLogin: user.lastLogin,
      webVersion: user.webVersion,
      extensionVersion: user.extensionVersion,
      isAdmin: user.isAdmin,
      status: (
        <Chip
          label={user.status.charAt(0).toUpperCase() + user.status.slice(1)}
          style={{
            backgroundColor: statusColors.bg,
            color: statusColors.text,
            padding: "1px 5px",
            fontWeight: "500",
          }}
          size="small"
        />
      ),
      action: (
        <Button
          component={Link}
          to={`/users/edit/${user.userId}`}
          variant="contained"
          color="primary"
          size="small"
        >
          Manage
        </Button>
      ),
    };
  });

  const headers = [
    { label: "User ID", key: "userId" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Email", key: "email" },
    { label: "Status", key: "status" },
    { label: "is Admin", key: "isAdmin" },
    { label: "Facebook User Name", key: "facebookUserName" },
    { label: "Created At", key: "createdAt" },
  ];

  const fetchAllUserDetails = async () => {
    setIsLoading(true);
    try {
      const respUsersDetails = await usersService.getAllUserDetails();
      console.log("resp Users Details: ", respUsersDetails?.data?.data);
      setUsersList(respUsersDetails?.data?.data?.users);
      setTotalUsers(respUsersDetails?.data?.data?.totalUsers);
      setActiveAccounts(respUsersDetails?.data?.data?.activeAccounts);
      setInactiveAccounts(respUsersDetails?.data?.data?.inactiveAccounts);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllUserDetails();
  }, []);

  return (
    <div>
      <Typography variant="h1" gutterBottom>
        Manage Users
      </Typography>
      <Divider sx={{ marginBottom: "1em" }} />
      <Box mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                textAlign: "center",
                borderRadius: "1rem",
                color: "#2196F3",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "4rem", fontWeight: "700", lineHeight: "1.1" }}
              >
                {totalUsers}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.1rem", fontWeight: "600" }}
              >
                Total Accounts
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                textAlign: "center",
                borderRadius: "1rem",
                color: "#4BA155",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "4rem", fontWeight: "700", lineHeight: "1.1" }}
              >
                {activeAccounts}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.1rem", fontWeight: "600" }}
              >
                Active Clients
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={2}
              sx={{
                p: 3,
                textAlign: "center",
                borderRadius: "1rem",
                color: "#d32f2f",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontSize: "4rem", fontWeight: "700", lineHeight: "1.1" }}
              >
                {inactiveAccounts}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "1.1rem", fontWeight: "600" }}
              >
                Inactive Clients
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <Box
        mb={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" gap={2} alignItems="center">
          <TextInputComponent
            label="Search"
            variant="outlined"
            value={search}
            onChange={handleSearch}
          />
          <Box sx={{ width: 120 }}>
            <SelectInputComponent
              labelId="status-filter-label"
              id="status-filter"
              name="status"
              label="Status"
              value={statusFilter}
              onChange={handleStatusFilterChange}
              options={[allOption, ...USER_STATUS_OPTIONS]}
              fullWidth
              required
            />
          </Box>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate("/users/create")}
          >
            Create New Account
          </Button>

          <CSVLink
            data={usersList}
            headers={headers}
            filename={`users-data-${getCurrentTimestamp()}.csv`}
          >
            <Button variant="outlined" startIcon={<FileDownloadIcon />}>
              Export CSV
            </Button>
          </CSVLink>
        </Box>
      </Box>
      {filteredUsers.length > 0 ? (
        <MUITableComponent
          tableData={tableData}
          columns={columns}
          hasActions={true}
        />
      ) : (
        <Typography variant="body1" align="center" sx={{ mt: 4 }}>
          No records found
        </Typography>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleManage}>
          <ManageAccountsIcon sx={{ mr: 1 }} /> Manage
        </MenuItem>
        <MenuItem onClick={handleUserActivity} disabled>
          <HistoryIcon sx={{ mr: 1 }} /> User Activity
        </MenuItem>
        <MenuItem onClick={handleViewMoreDetails}>
          <InfoIcon sx={{ mr: 1 }} /> View More Details
        </MenuItem>
        <MenuItem onClick={handleCourseEnrollment}>
          <SchoolIcon sx={{ mr: 1 }} /> Course Enrollment
        </MenuItem>
      </Menu>

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        disableEscapeKeyDown={true}
        maxWidth={"md"}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& td": {
            padding: "9px",
          },
        }}
        PaperProps={{ sx: { mt: "50px", borderRadius: "20px" } }}
      >
        <DialogTitle>User Details</DialogTitle>
        <Divider />
        <DialogContent>
          {selectedUser && (
            <Paper variant="outlined">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Name:</strong>
                    </TableCell>
                    <TableCell>{selectedUser.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Email:</strong>
                    </TableCell>
                    <TableCell>{selectedUser.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Facebook Username:</strong>
                    </TableCell>
                    <TableCell>
                      {selectedUser.facebookUserName || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Join Date:</strong>
                    </TableCell>
                    <TableCell>{selectedUser.createdAt}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Status:</strong>
                    </TableCell>
                    <TableCell>{selectedUser.status}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Is Admin:</strong>
                    </TableCell>
                    <TableCell>{selectedUser.isAdmin ? "Yes" : "No"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Last Login:</strong>
                    </TableCell>
                    <TableCell>{selectedUser?.lastLogin ? formatDateTimeToLongString(selectedUser?.lastLogin) : "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Web Version:</strong>
                    </TableCell>
                    <TableCell>{selectedUser.webVersion || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Extension Version:</strong>
                    </TableCell>
                    <TableCell>
                      {selectedUser.extensionVersion || "-"}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Paper>
          )}
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button variant="outlined" onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default ManageUsersPage;
