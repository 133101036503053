// TextInputComponent.jsx
import React, { useState } from "react";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function TextInputComponent({
  label,
  value,
  name,
  onChange,
  onBlur,
  error,
  helperText,
  required = false,
  type = "text",
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const inputType =
    type === "password" ? (showPassword ? "text" : "password") : type;

  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel htmlFor={`${name}-input`}>
        {label}{" "}
        {required && (
          <Typography color="error" component="span">
            *
          </Typography>
        )}
      </InputLabel>
      <OutlinedInput
        id={`${name}-input`}
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
        size="small"
        sx={{ height: "2.5rem" }}
        type={inputType}
        endAdornment={
          type === "password" ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
                size="small"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
        {...props}
      />
      {error && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
          }}
        >
          {error}
        </FormHelperText>
      )}
      {helperText && (
        <FormHelperText
          sx={{
            marginLeft: 0,
            fontStyle: "italic",
            fontWeight: 600,
            fontSize: "0.77rem",
            letterSpacing: 0,
          }}
        >
          {helperText}
        </FormHelperText>
      )}
    </Box>
  );
}

export default TextInputComponent;