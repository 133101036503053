/*
 * Header component
 */

import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import { styled } from "@mui/system";
import vectorImg from "../logo.svg";
import packageJson from "../../package.json";
import { useNavigate } from "react-router-dom";
import usersService from "../services/usersService";
import { useUser } from '../context/UserContext';

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  borderTop: "4px solid #D1AA6A",
  backgroundColor: "white",
  boxShadow: "none",
  borderBottomWidth: "1px",
  borderBottomColor: "#e0e0e0",
  borderBottomStyle: "solid",
  "& .MuiToolbar-root": {
    padding: 0,
    minHeight: "54px",
  },
}));

const MenuStyled = styled(Menu)({
  "& li": {
    fontSize: "14px",
  },
  "& .version": {
    fontSize: "14px",
    color: "#9e9e9e",
    paddingRight: "16px",
    paddingLeft: "16px",
  },
});

function HeaderComponent() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { user } = useUser();
  const { clearUser } = useUser();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await usersService.logout();
      clearUser();
      navigate("/sign-in");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <AppBarStyled position="fixed">
      <Toolbar>
        <Typography
          component="div"
          sx={{ flexGrow: 1, padding: "0 20px", display: "flex" }}
        >
          <img alt="YunaPro logo" src={vectorImg} />
        </Typography>
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            sx={{
              paddingTop: 0,
              paddingBottom: 0,
              paddingRight: 0,
              marginRight: "12px",
              display: "flex",
              borderRadius: "20px",
              "&:focus": {
                outline: "1px solid #8e8e8e",
                borderRadius: "20px",
                backgroundColor: "rgba(0, 0, 0, 0.08)",
              },
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)",
                boxShadow: "none",
              },
            }}
            disableRipple
          >
            <Typography
              component="span"
              className="flex font-medium"
              sx={{
                paddingRight: "0.5rem",
                color: "#000000de",
                fontSize: "0.875rem",
              }}
            >
              {user?.email}
            </Typography>
            <Avatar sx={{ backgroundColor: "#D8A85F" }}>{user?.firstName[0].toUpperCase()}</Avatar>
          </IconButton>
          <MenuStyled
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose} disabled>Account Details</MenuItem>
            <MenuItem onClick={handleLogout}>Log Out</MenuItem>
            <Divider />
            <Box>
              <Typography>
                <span className="version">
                  Version: {packageJson?.version || "N/A"}
                </span>
              </Typography>
            </Box>
          </MenuStyled>
        </div>
      </Toolbar>
    </AppBarStyled>
  );
}

export default HeaderComponent;
