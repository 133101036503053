import React, { useState } from "react";
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function TextInputComponent2({
  label,
  value,
  name,
  onChange,
  onBlur,
  error,
  helperText,
  required = false,
  type = "text",
  ...props
}) {
  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel htmlFor={`${name}-input`}>
        {label}{" "}
        {helperText && (
          <FormHelperText
            sx={{
              marginLeft: 1,
              fontStyle: "italic",
              fontWeight: 600,
              fontSize: "0.77rem",
              letterSpacing: 0,
            }}
            component="span"
          >
            ({helperText})
          </FormHelperText>
        )}
      </InputLabel>
      <OutlinedInput
        id={`${name}-input`}
        fullWidth
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        error={!!error}
        size="small"
        sx={{ height: "2.5rem" }}
        type={type}
        {...props}
      />
      {error && (
        <FormHelperText
          error
          sx={{
            marginLeft: 0,
          }}
        >
          {error}
        </FormHelperText>
      )}
    </Box>
  );
}

export default TextInputComponent2;
