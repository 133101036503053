import React from "react";
import { Typography, Paper, Divider, Box } from "@mui/material";
import Chart from "react-apexcharts";

function UserGrowthChart({ userData }) {
  // Filter out users with invalid dates and sort
  const sortedUsers = userData
    .filter((user) => !isNaN(new Date(user.createdAt).getTime()))
    .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  // Prepare data for cumulative user growth
  const growthData = sortedUsers.map((user, index) => ({
    x: new Date(user.createdAt).getTime(),
    y: index + 1,
  }));

  const chartOptions = {
    chart: { id: "user-growth", type: "area" },
    xaxis: { type: "datetime" },
    yaxis: { title: { text: "Total Users" } },
    title: { text: "User Growth Over Time" },
  };

  const series = [
    {
      name: "Total Users",
      data: growthData,
    },
  ];

  return (
    <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Typography variant="h2" component="h2" gutterBottom>
          User Growth Trend
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        {growthData.length > 0 ? (
          <Chart
            options={chartOptions}
            series={series}
            type="area"
            height={300}
          />
        ) : (
          <Typography>No valid user data available</Typography>
        )}
      </Box>
    </Paper>
  );
}

export default UserGrowthChart;
