import { Editor } from "@tinymce/tinymce-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  FormControlLabel,
  Switch,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Backdrop,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadIcon from "@mui/icons-material/Upload";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TextInputComponent from "../../components/TextInputComponent";
import SelectInputComponent from "../../components/SelectInputComponent";
import courseService from "../../services/courseService";
import {
  allowedExtensions,
  allowedTypes,
  generateUniqueIdentifier,
  maxSizeInBytes,
} from "../../utils/constant";
import AlertSnackbar from "../../components/AlertSnackbar";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent";

const schema = (isFree) =>
  yup.object().shape({
    title: yup.string().required("Course title is required"),
    image: yup
      .mixed()
      .test("fileSize", "File size exceeds 1 MB", (value) => {
        if (!value || !value[0]) return true; // No file selected, so no validation needed
        return value[0].size <= maxSizeInBytes;
      })
      .test("fileType", "Only JPEG and PNG files are allowed", (value) => {
        if (!value || !value[0]) return true; // No file selected, so no validation needed
        const fileType = value[0].type;
        const fileExtension = value[0].name.split('.').pop().toLowerCase();
        return allowedTypes.includes(fileType) && allowedExtensions.includes(fileExtension);
      }),
    price: isFree
      ? yup.number()
      : yup
          .number()
          .positive("Price must be a positive number")
          .required("Price is required"),
    stripeProductName: !isFree && yup.string().required("Stripe Product Name is required"),
  });

const SortableItem = ({ lesson, onDelete, onEdit, onCopy }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: lesson.lessonId.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      {...attributes}
      sx={{
        paddingTop: "12px",
        paddingBottom: "12px",
        display: "flex",
        alignItems: "center",
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
      }}
    >
      <Box {...listeners} sx={{ marginRight: 2, cursor: "move" }}>
        <DragIndicatorIcon />
      </Box>
      <ListItemText
        primary={lesson.title}
        secondary={`Order: ${lesson.order}`}
      />
      <IconButton
        edge="end"
        aria-label="copy"
        onClick={() => onCopy(lesson)}
        sx={{ marginRight: 1 }}
        title="Duplicate"
      >
        <ContentCopyIcon />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="edit"
        onClick={() => onEdit(lesson)}
        sx={{ marginRight: 1 }}
        title="Edit"
      >
        <EditIcon />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => onDelete(lesson.lessonId)}
        title="Delete"
      >
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

const CreateNewCoursePage = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState({
    title: "",
    description: "",
    isFree: true,
    price: 0,
    stripeProductName: null,
    status: "draft",
    thumbnailUrl: "",
  });
  const [isFree, setIsFree] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [isAddingLesson, setIsAddingLesson] = useState(false);
  const [isEditingLesson, setIsEditingLesson] = useState(false);
  const [imageFieldError, setImageFieldError] = useState("");
  const [open, setOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [lessonTitleError, setLessonTitleError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentLesson, setCurrentLesson] = useState({
    title: "",
    content: "",
    videoUrl: "",
    order: 1,
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const imageFileName = useMemo(() => generateUniqueIdentifier(), []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema(isFree)),
    defaultValues: {
      title: "",
      image: "",
      description: "",
      isFree: true,
      price: 0,
      stripeProductName: null,
      status: "draft",
      thumbnailUrl: "",
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourse((prev) => ({ ...prev, [name]: value }));
  };

  const handleSwitchChange = (e) => {
    setIsFree(e.target.checked);
    setCourse((prev) => ({
      ...prev,
      isFree: e.target.checked,
      price: e.target.checked ? 0 : prev.price,
      stripeProductName: e.target.checked ? null : prev.stripeProductName,
    }));
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setThumbnailPreview(URL.createObjectURL(file));
    setValue("image", e.target.files);
  };

  const onSubmit = async (data) => {
    handleCreateCourse(data);
  };

  const handleCreateCourse = async (data) => {
    console.log("Creating new course:", { ...course, lessons });
    setIsLoading(true);
    try {
      let imageUrl = null;
      if (selectedFile) {
        console.log("Selected File: ", selectedFile);
        imageUrl = await handleFileUpload(selectedFile);
        console.log(imageUrl);
        if (imageUrl?.error) {
          setImageFieldError("Error in image uploading.");
        }
      }
      let newCourse = {
        ...course,
        title: data.title,
        thumbnailUrl: imageUrl?.data,
        price: isFree ? 0 : data.price,
        stripeProductName: isFree ? null : data.stripeProductName,
      };
      const respCreateCourse = await courseService.createCourse(newCourse);
      console.log("resp Create Course:", respCreateCourse);
      const courseId = respCreateCourse?.data.data.courseId;
      if (courseId && lessons.length) {
        // Add courseId to each lesson
        const updatedLessons = lessons.map((lesson) => {
          const { lessonId, ...lessonWithoutId } = lesson;
          return {
            ...lessonWithoutId,
            courseId,
          };
        });
        console.log("Updated lessons:", updatedLessons);
        const respCreateLessons = await courseService.addLesson({
          lessons: updatedLessons,
        });
        console.log("resp Create Lessons:", respCreateLessons);
      }
      navigate("/courses");
    } catch (error) {
      console.log("error: ", error);
    }
    setIsLoading(false);
  };

  const handleAddLesson = () => {
    setIsAddingLesson(true);
    setCurrentLesson({
      title: "",
      content: "",
      videoUrl: "",
      order: lessons.length + 1,
    });
  };

  const handleEditLesson = (lesson) => {
    setIsEditingLesson(true);
    setCurrentLesson(lesson);
  };

  const handleCopyLesson = (lessonToCopy) => {
    setIsLoading(true);
    const newLesson = {
      ...lessonToCopy,
      lessonId: Date.now(),
      title: `${lessonToCopy.title} (Copy)`,
      order: lessons.length + 1,
    };
    setLessons((prev) => [...prev, newLesson]);
    setSnackbar({
      open: true,
      message: "The lesson has been copied.",
      severity: "success",
    });
    setIsLoading(false);
  };

  const handleSaveLesson = () => {
    if (!currentLesson.title.trim()) {
      setLessonTitleError("Lesson title is required.");
      return;
    }

    setIsLoading(true);
    if (isEditingLesson) {
      setLessons((prev) =>
        prev.map((lesson) =>
          lesson.lessonId === currentLesson.lessonId ? currentLesson : lesson
        )
      );
      setSnackbar({
        open: true,
        message: "The lesson has been updated.",
      });
    } else {
      setLessons((prev) => [
        ...prev,
        { ...currentLesson, lessonId: Date.now() },
      ]);
      setSnackbar({
        open: true,
        message: "The lesson has been added.",
      });
    }
    setIsAddingLesson(false);
    setIsEditingLesson(false);
    setCurrentLesson({
      title: "",
      content: "",
      videoUrl: "",
      order: lessons.length + 1,
    });
    setLessonTitleError("");
    setIsLoading(false);
  };

  const handleDeleteLesson = () => {
    setIsLoading(true);
    setLessons((prev) =>
      prev.filter((lesson) => lesson.lessonId !== itemToDelete)
    );
    console.log("Deleting item");
    setSnackbar({
      open: true,
      message: "The lesson has been deleted.",
    });
    setIsLoading(false);
  };

  const handleConfirm = () => {
    handleDeleteLesson();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleLessonInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentLesson((prev) => ({ ...prev, [name]: value }));
    if (name === "title") {
      setLessonTitleError("");
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setLessons((items) => {
        const oldIndex = items.findIndex(
          (item) => item.lessonId.toString() === active.id
        );
        const newIndex = items.findIndex(
          (item) => item.lessonId.toString() === over.id
        );

        return arrayMove(items, oldIndex, newIndex).map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      });
    }
  };

  const handleFileUpload = async (selectedFile) => {
    if (!selectedFile) return;

    // File size check
    if (selectedFile.size > maxSizeInBytes) {
      console.error("Error: File size exceeds 1 MB.");
      setImageFieldError("File size exceeds 1 MB.");
      return null;
    }

    // File type and extension check
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    if (
      !allowedTypes.includes(selectedFile.type) ||
      !allowedExtensions.includes(fileExtension)
    ) {
      console.error(
        "Error: File type not allowed. Only JPEG and PNG are allowed."
      );
      setImageFieldError("File type not allowed. Only JPEG and PNG are allowed.");
      return null;
    }

    setImageFieldError("");
    const formData = new FormData();
    const ext = selectedFile?.name?.split(".");
    const imageName = ext?.length
      ? `${imageFileName}.${ext[ext?.length - 1]}`
      : imageFileName;
    const newFile = new File([selectedFile], imageName, {
      type: selectedFile.type,
      lastModified: selectedFile.lastModified,
    });
    formData.append("fileUpload", newFile);
    try {
      const response = await courseService.saveCourseImage(formData);
      console.log("File uploaded successfully:", response.data);
      return { data: response?.data?.data, error: null };
    } catch (error) {
      console.error("Error uploading file:", error);
      return { data: null, error: error };
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          onClick={() => navigate("/courses")}
          aria-label="go back"
          title="Go Back"
          sx={{ marginBottom: "0.35em", marginRight: "0.1em" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1" gutterBottom>
          Create New Course
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: "1em" }} />

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ mb: 3, borderRadius: "8px" }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextInputComponent
                        {...field}
                        label="Course Title"
                        error={errors.title?.message}
                        required={true}
                      />
                    )}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ mt: 2, fontSize: "14px", fontWeight: "500" }}
                  >
                    Description
                  </Typography>
                  <Editor
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    value={course?.description || ""}
                    id="fancy-text-tool-area"
                    name="description"
                    init={{
                      height: 200,
                      entity_encoding: "raw",
                      license_key: "gpl",
                      plugins: [
                        "autolink",
                        "lists",
                        "link",
                        "charmap",
                        "preview",
                        "searchreplace",
                        "fullscreen",
                        "media",
                        "table",
                        "code",
                        "emoticons",
                      ],
                      toolbar:
                        "undo redo | " +
                        "formatselect | " +
                        "bold italic underline strikethrough | " +
                        "alignleft aligncenter alignright | " +
                        "bullist numlist | " +
                        "link emoticons | " +
                        "code removeformat",
                      emoticons_append: {
                        custom_mind_explode: {
                          keywords: ["brain", "mind", "explode", "blown"],
                          char: "🤯",
                        },
                      },
                      branding: false,
                      statusbar: false,
                      menubar: false,
                      content_style: "p { margin: 0}",
                    }}
                    onEditorChange={(newValue, editor) => {
                      setCourse((prev) => ({ ...prev, description: newValue }));
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isFree}
                        onChange={handleSwitchChange}
                        name="isFree"
                        // disabled={true}
                      />
                    }
                    label="Free Course"
                    sx={{ mb: 1, mt: 1 }}
                  />
                  {!isFree && (
                    <>
                      <Controller
                        name="price"
                        control={control}
                        render={({ field }) => (
                          <TextInputComponent
                            {...field}
                            label="Price"
                            type="number"
                            error={errors.price?.message}
                            required={!isFree}
                          />
                        )}
                      />
                      <Controller
                        name="stripeProductName"
                        control={control}
                        render={({ field }) => (
                          <TextInputComponent
                            {...field}
                            label="Stripe Product Name"
                            error={errors.stripeProductName?.message}
                            required={!isFree}
                          />
                        )}
                      />
                    </>
                  )}
                  <SelectInputComponent
                    labelId="status-label"
                    name="status"
                    label="Status"
                    value={course.status}
                    onChange={handleInputChange}
                    options={[
                      { label: "Draft", value: "draft" },
                      { label: "Published", value: "published" },
                      { label: "Archived", value: "archived" },
                    ]}
                    fullWidth
                    required
                  />
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="thumbnail-upload"
                    type="file"
                    onChange={handleThumbnailChange}
                  />
                  <label htmlFor="thumbnail-upload">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<UploadIcon />}
                      sx={{ mb: 1 }}
                    >
                      Upload Thumbnail
                    </Button>
                  </label>
                  {errors.image && (
                    <FormHelperText error>
                      {errors.image.message}
                    </FormHelperText>
                  )}
                  {imageFieldError && (
                    <FormHelperText
                      error
                      sx={{
                        marginLeft: 0,
                      }}
                    >
                      {imageFieldError}
                    </FormHelperText>
                  )}
                  <FormHelperText
                    sx={{
                      marginLeft: 0,
                      fontStyle: "italic",
                      fontWeight: 600,
                      fontSize: "0.77rem",
                      letterSpacing: 0,
                    }}
                  >
                    [Max file size is 1 MB. Only JPEG (.jpg, .jpeg) and PNG (.png) file formats are allowed.]
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={4}>
                  {thumbnailPreview && !imageFieldError && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Thumbnail Preview
                      </Typography>
                      <img
                        src={thumbnailPreview}
                        alt="Course thumbnail"
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Box
                sx={{
                  padding: "0.8rem 1.2rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h2" gutterBottom>
                  Lessons
                </Typography>
                <Button startIcon={<AddIcon />} onClick={handleAddLesson}>
                  Add Lesson
                </Button>
              </Box>

              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={lessons.map((l) => l.lessonId.toString())}
                  strategy={verticalListSortingStrategy}
                >
                  <List sx={{ padding: 0 }}>
                    {lessons.map((lesson) => (
                      <SortableItem
                        key={lesson.lessonId}
                        lesson={lesson}
                        onDelete={() => {
                          setItemToDelete(lesson.lessonId);
                          setOpen(true);
                        }}
                        onEdit={handleEditLesson}
                        onCopy={handleCopyLesson}
                      />
                    ))}
                  </List>
                </SortableContext>
              </DndContext>
            </Box>
            <Divider />
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Button
                type="submit"
                variant="contained"
                className="w-full py-3 mt-6"
                disabled={isLoading}
              >
                {isLoading ? "Creating Course..." : "Create Course"}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>

      <Dialog
        open={isAddingLesson || isEditingLesson}
        onClose={() => {
          setIsAddingLesson(false);
          setIsEditingLesson(false);
        }}
        disableEscapeKeyDown={true}
        maxWidth={"md"}
        fullWidth
        sx={{ "& .MuiDialog-container": { alignItems: "flex-start" } }}
        PaperProps={{ sx: { mt: "50px", borderRadius: "20px" } }}
      >
        <DialogTitle>
          {isEditingLesson ? "Edit Lesson" : "Add New Lesson"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <TextInputComponent
            name="title"
            label="Lesson Title"
            value={currentLesson.title}
            onChange={handleLessonInputChange}
            fullWidth
            required={true}
            error={lessonTitleError}
          />
          <TextInputComponent
            name="videoUrl"
            label="Video URL"
            value={currentLesson.videoUrl}
            onChange={handleLessonInputChange}
            fullWidth
          />
          <Typography
            variant="subtitle1"
            sx={{ mt: 2, fontSize: "14px", fontWeight: "500" }}
          >
            Lesson Content / Notes
          </Typography>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            value={currentLesson.content}
            id="fancy-text-tool-area-addedit"
            name="content"
            init={{
              height: 200,
              entity_encoding: "raw",
              license_key: "gpl",
              plugins: [
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "searchreplace",
                "fullscreen",
                "media",
                "table",
                "code",
                "emoticons",
              ],
              toolbar:
                "undo redo | " +
                "formatselect | " +
                "bold italic underline strikethrough | " +
                "alignleft aligncenter alignright | " +
                "bullist numlist | " +
                "link emoticons | " +
                "code removeformat",
              emoticons_append: {
                custom_mind_explode: {
                  keywords: ["brain", "mind", "explode", "blown"],
                  char: "🤯",
                },
              },
              branding: false,
              statusbar: false,
              menubar: false,
              content_style: "p { margin: 0}",
            }}
            onEditorChange={(newValue, editor) => {
              setCurrentLesson((prev) => ({ ...prev, content: newValue }));
            }}
          />
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button
            variant="outlined"
            onClick={() => {
              setIsAddingLesson(false);
              setIsEditingLesson(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSaveLesson}
            variant="contained"
            disabled={!currentLesson.title.trim() || isLoading}
          >
            {isLoading
              ? isEditingLesson
                ? "Saving Changes..."
                : "Adding Lesson..."
              : isEditingLesson
              ? "Save Changes"
              : "Add Lesson"}
          </Button>
        </DialogActions>
      </Dialog>

      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      <ConfirmationDialogComponent
        open={open}
        title="Confirm Deletion"
        message={`Are you sure you want to delete?`}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreateNewCoursePage;
