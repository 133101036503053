import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink, useLocation } from "react-router-dom";

const drawerWidth = 270;

const DrawerStyled = styled(Drawer)({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
  },
});

const ListStyled = styled(List)({
  paddingLeft: "0.8rem",
  paddingRight: "0.8rem",
  "& a": {
    textDecoration: "none",
    color: "inherit",
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "8px 14px",
    marginBottom: "0.25rem",
    borderRadius: "8px",
  },
  "& a.active": {
    color: "#3C7E44",
    backgroundColor: "#4ca1541f",
  },
  "& a.active svg": {
    color: "#3C7E44",
  },
  "& svg": {
    fontSize: "1.1rem",
  },
  "& span": {
    fontSize: "0.8750em",
  },
  "& li": {
    padding: 0,
  },
  "& .active-parent": {
    color: "#3C7E44",
    backgroundColor: "#4ca1541f",
    borderRadius: "8px",
  },
  "& .active-parent svg": {
    color: "#3C7E44",
  },
});

const SubMenuStyled = styled(List)({
  paddingLeft: "1.5rem",
  "& li": {
    position: "relative",
    padding: 0,
  },
  "& a": {
    padding: "8px 14px 8px 20px",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      left: 0,
      top: 0,
      bottom: 0,
      width: 2,
      backgroundColor: "#e0e0e0",
    },
  },
  "& a.active": {
    backgroundColor: "#4ca1541f",
    "&::before": {
      backgroundColor: "#2C6E34",
    },
  },
});

function SidebarComponent() {
  const location = useLocation();

  const menuItems = [
    { text: "Dashboard", icon: <DashboardIcon />, path: "/dashboard" },
    {
      text: "Users",
      icon: <PeopleIcon />,
      subItems: [
        { text: "Manage Users", path: "/users" },
        { text: "Create User", path: "/users/create" },
      ],
    },
    {
      text: "Courses",
      icon: <SchoolIcon />,
      subItems: [
        { text: "Manage Courses", path: "/courses" },
        { text: "Create Course", path: "/courses/create" },
        { text: "Reorder Courses", path: "/courses/order" },
      ],
    },
    { text: "Manage Params", icon: <SettingsIcon />, path: "/params" },
  ];

  const isUsersActive = menuItems
    .find((item) => item.text === "Users")
    ?.subItems.some((subItem) => location.pathname === subItem.path);

  const isCoursesActive = menuItems
    .find((item) => item.text === "Courses")
    ?.subItems.some((subItem) => location.pathname === subItem.path);

  const [openUsers, setOpenUsers] = useState(isUsersActive);
  const [openCourses, setOpenCourses] = useState(isCoursesActive);

  useEffect(() => {
    if (isUsersActive) {
      setOpenUsers(true);
    }
    if (isCoursesActive) {
      setOpenCourses(true);
    }
  }, [location.pathname, isUsersActive, isCoursesActive]);

  const handleUsersClick = () => {
    setOpenUsers(!openUsers);
  };

  const handleCoursesClick = () => {
    setOpenCourses(!openCourses);
  };

  return (
    <DrawerStyled variant="permanent">
      <Toolbar />
      <ListStyled>
        {menuItems.map((item) => (
          <React.Fragment key={item.text}>
            {item.subItems ? (
              <>
                <ListItem
                  button
                  onClick={
                    item.text === "Users"
                      ? handleUsersClick
                      : handleCoursesClick
                  }
                  className={
                    item.subItems.some(
                      (subItem) => location.pathname === subItem.path
                    )
                      ? "active-parent"
                      : ""
                  }
                >
                  <ListItemIcon sx={{ minWidth: "28px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                  {(item.text === "Users" ? openUsers : openCourses) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </ListItem>
                <Collapse
                  in={item.text === "Users" ? openUsers : openCourses}
                  timeout="auto"
                  unmountOnExit
                >
                  <SubMenuStyled>
                    {item.subItems.map((subItem) => (
                      <ListItem key={subItem.text}>
                        <NavLink
                          to={subItem.path}
                          className={({ isActive }) =>
                            location.pathname === subItem.path ? "active" : ""
                          }
                        >
                          <ListItemText primary={subItem.text} />
                        </NavLink>
                      </ListItem>
                    ))}
                  </SubMenuStyled>
                </Collapse>
              </>
            ) : (
              <ListItem>
                <NavLink
                  to={item.path}
                  end
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  <ListItemIcon sx={{ minWidth: "28px" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </NavLink>
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </ListStyled>
    </DrawerStyled>
  );
}

export default SidebarComponent;
