import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Box,
  Typography,
  Button,
  Paper,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import userService from "../services/usersService";
import TextInputComponent from "../components/TextInputComponent";
import vectorImg from "../logo.svg";
import AlertSnackbar from "../components/AlertSnackbar";
import { useUser } from '../context/UserContext';

const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter an email'),
  password: yup
    .string()
    .required('Please enter your password.')
    .min(4, 'Password is too short - must be at least 4 chars.'),
});

const SignInPage = () => {
  const navigate = useNavigate();
  const { updateUser } = useUser();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [isLoading, setIsLoading] = useState(false);

  const { control, formState: { errors }, handleSubmit } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const userData = await userService.signInWithEmailAndPassword(data.email, data.password);
      updateUser({
        email: userData.email,
        firstName: userData.firstName,
        lastName: userData.lastName,
      });
      navigate("/dashboard");
    } catch (error) {
      setSnackbar({
        open: true,
        message: error?.response?.data?.responseMessge || "Something went wrong.",
        severity: "error",
      });
    }
    setIsLoading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <Paper variant="outlined" sx={{ padding: 4, width: 500, borderRadius: "20px", boxShadow: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              padding: "1rem 1rem 2rem 1rem",
              "& img": { height: "5rem" },
            }}
          >
            <img alt="YunaPro logo" src={vectorImg} />
          </Box>
          <Typography
            variant="h1"
            component="h1"
            sx={{ paddingBottom: "2rem" }}
          >
            Sign In to YunaPRO Admin
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextInputComponent
                  {...field}
                  label="Email Address"
                  type="email"
                  error={errors.email?.message}
                  autoFocus
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextInputComponent
                  {...field}
                  name="password"
                  label="Password"
                  type="password"
                  error={errors.password?.message}
                />
              )}
            />
            <Button type="submit" variant="contained" fullWidth sx={{ mt: 1 }}>
              Sign In
            </Button>
          </form>
        </Paper>
      </Box>
      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default SignInPage;