const userServiceConfig = {
	adminSignIn: '/admin/login',
	signIn: '/login',
	accessToken: 'api/auth/access-token',
	changePassword: '/change-password',
	userDetail: '/user-detail',
	getUserDetailByEmail: '/get-userdetail-by-email',
	updateUser: 'api/auth/user/update',
	forgotPassword: '/forgot-password',
	updatePasword: '/reset-password',
	getAllUserDetails: '/get-all-userdetail',
};

export default userServiceConfig;
