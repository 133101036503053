import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import userService from '../services/usersService';
import AdminLayout from '../layouts/AdminLayout';

function PrivateRoute() {
  const isAuthenticated = userService.isAuthenticated()
  return isAuthenticated ? <AdminLayout><Outlet/></AdminLayout> : <Navigate to="/sign-in" />;
}

export default PrivateRoute;