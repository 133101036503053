import React, { useState, useEffect } from "react";
import { Divider, Typography, Grid } from "@mui/material";
import AdminRatioChart from "../dashboard/components/AdminRatioChart";
import CourseStatusChart from "../dashboard/components/CourseStatusChart";
import UserActivityChart from "../dashboard/components/UserActivityChart";
import UserGrowthChart from "../dashboard/components/UserGrowthChart";
import UserStatusChart from "../dashboard/components/UserStatusChart";
import usersService from "../../services/usersService";
import courseService from "../../services/courseService";

function DashboardPage() {
  const [userData, setUserData] = useState([]);
  const [courseData, setCourseData] = useState([]);

  const fetchAllUserDetails = async () => {
    try {
      const respUsersDetails = await usersService.getAllUserDetails();
      console.log("resp Users Details: ", respUsersDetails?.data?.data);
      setUserData(
        Array.isArray(respUsersDetails?.data?.data?.users)
          ? respUsersDetails.data.data.users
          : []
      );

    } catch (error) {
      console.error("Error fetching user details:", error);
      setUserData([]);
    }
  };

  const fetchAllCourses = async () => {
    try {
      const respCourses = await courseService.getAllCourse();
      console.log("resp Courses: ", respCourses?.data?.data);
      setCourseData(respCourses?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllUserDetails();
    fetchAllCourses();
  }, []);

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Divider sx={{ marginBottom: "1em" }} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <UserGrowthChart userData={userData} />
        </Grid>
        <Grid item xs={12} md={5}>
          <UserStatusChart userData={userData} />
        </Grid>
        <Grid item xs={12} md={7}>
          <UserActivityChart userData={userData} />
        </Grid>
        <Grid item xs={12} md={5}>
          <AdminRatioChart userData={userData} />
        </Grid>
        <Grid item xs={12} md={12}>
          <CourseStatusChart courseData={courseData} />
        </Grid>
      </Grid>
    </div>
  );
}

export default DashboardPage;
