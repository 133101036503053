import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  TextField,
  Select,
  MenuItem,
  Button,
  Box,
  CircularProgress,
  Backdrop,
  Typography,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import paramService from "../../services/paramService";
import { searchParams } from "../../utils/constant";
import AlertSnackbar from "../../components/AlertSnackbar";

const ManageParams = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedFeature, setSelectedFeature] = useState("gain-reciprocity");
  const [editingRow, setEditingRow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableReload, setIsTableReload] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchUsersParams(selectedFeature);
    setIsTableReload(false);
  }, [selectedFeature, isTableReload]);

  const fetchUsersParams = async (featureName) => {
    try {
      const response = await paramService.getAllParamByFeature(featureName);
      if (response.status === 200) {
        setUsers(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching user params:", error);
    }
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const handleFeatureChange = (event) => {
    setSelectedFeature(event.target.value);
  };

  const handleEdit = (userId) => {
    setEditingRow(userId);
  };

  const handleSave = async (user) => {
    setIsLoading(true);
    try {
      const featureParams = searchParams[selectedFeature] || [];
      const paramNames = featureParams.map((param) => param.name);
      const filteredData = Object.entries(
        user.urlParams[0]?.params || {}
      ).reduce((acc, [key, value]) => {
        if (paramNames.includes(key) && value !== "" && value !== undefined) {
          acc[key] = value;
        }
        return acc;
      }, {});

      const updateData = {
        featureName: selectedFeature,
        params: filteredData,
      };

      const response = await paramService.addParams(updateData, user.userId);

      if (response.status === 200) {
        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.userId === user.userId
              ? {
                  ...u,
                  urlParams: u.urlParams.map((param) =>
                    param.featureName === selectedFeature
                      ? { ...param, params: filteredData }
                      : param
                  ),
                }
              : u
          )
        );

        setSnackbar({
          open: true,
          message: "User params details updated successfully",
          severity: "success",
        });
        setIsTableReload(true);
      } else {
        throw new Error("Failed to update user params");
      }
    } catch (error) {
      console.error("Error updating user params details:", error);
      setSnackbar({
        open: true,
        message:
          error?.response?.data?.message ||
          "An error occurred while updating params",
        severity: "error",
      });
    }
    setIsLoading(false);
    setEditingRow(null);
  };

  const handleCancel = () => {
    fetchUsersParams(selectedFeature);
    setEditingRow(null);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const filteredUsers = users.filter(
    (user) =>
      `${user.firstName} ${user.lastName || ""}`
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      user.email.toLowerCase().includes(searchText.toLowerCase())
  );

  const paginatedUsers = filteredUsers.slice(
    page * rowsPerPage,
    (page + 1) * rowsPerPage
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    { id: "name", label: "User Name", minWidth: "14rem" },
    ...searchParams[selectedFeature].map((param) => ({
      id: param.name,
      label: param.name,
      minWidth: 100,
      type: param.type,
      min: param.type === "number" ? param.min : undefined,
      max: param.type === "number" ? param.max : undefined,
    })),
    { id: "action", label: "Action", minWidth: 100 },
  ];

  const handleParamChange = (userId, paramName, value) => {
    setUsers((prevUsers) => {
      return prevUsers.map((user) => {
        if (user.userId === userId) {
          const updatedParams = {
            ...(user.urlParams[0]?.params || {}),
            [paramName]: value,
          };
          return {
            ...user,
            urlParams: [
              {
                ...(user.urlParams[0] || {}),
                params: updatedParams,
              },
            ],
          };
        }
        return user;
      });
    });
  };

  return (
    <Box>
      <Typography variant="h1" gutterBottom>
        Manage Params
      </Typography>

      <Divider sx={{ marginBottom: "1em" }} />
      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          variant="outlined"
          size="small"
          onChange={handleSearch}
          placeholder="Search user..."
        />
        <Select
          value={selectedFeature}
          onChange={handleFeatureChange}
          size="small"
        >
          <MenuItem value="gain-reciprocity">Gain Reciprocity</MenuItem>
          <MenuItem value="generate-leads">Generate Leads</MenuItem>
          <MenuItem value="scan-friend-activity" disabled={true}>
            Scan Friend Activity
          </MenuItem>
        </Select>
      </Box>
      <Divider />
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow
              sx={{
                "& .MuiTableCell-root": {
                  backgroundColor: "#F5F5F5",
                },
              }}
            >
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  style={{
                    minWidth: column.minWidth,
                    width: column.id === "name" ? "14rem" : "auto",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={user.userId}>
                {columns.map((column) => {
                  if (column.id === "name") {
                    return (
                      <TableCell key={column.id}>
                        {`${user.firstName} ${user.lastName || ""}`.trim()}
                      </TableCell>
                    );
                  }
                  if (column.id === "action") {
                    return (
                      <TableCell key={column.id}>
                        {editingRow === user.userId ? (
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <Button
                              startIcon={<SaveIcon />}
                              onClick={() => handleSave(user)}
                              variant="contained"
                              size="small"
                            >
                              Save
                            </Button>
                            <Button
                              startIcon={<CancelIcon />}
                              onClick={handleCancel}
                              variant="outlined"
                              size="small"
                            >
                              Cancel
                            </Button>
                          </Box>
                        ) : (
                          <Button
                            startIcon={<EditIcon />}
                            onClick={() => handleEdit(user.userId)}
                            variant="outlined"
                            size="small"
                          >
                            Edit
                          </Button>
                        )}
                      </TableCell>
                    );
                  }
                  const paramValue = user.urlParams.find(
                    (p) => p.featureName === selectedFeature
                  )?.params?.[column.id];
                  return (
                    <TableCell key={column.id}>
                      {editingRow === user.userId ? (
                        column.type === "boolean" ? (
                          <Select
                            value={paramValue}
                            onChange={(e) =>
                              handleParamChange(
                                user.userId,
                                column.id,
                                e.target.value
                              )
                            }
                            size="small"
                            fullWidth
                          >
                            <MenuItem value="">
                              <em>None</em>
                            </MenuItem>
                            <MenuItem value={true}>True</MenuItem>
                            <MenuItem value={false}>False</MenuItem>
                          </Select>
                        ) : (
                          <TextField
                            type={column.type === "number" ? "number" : "text"}
                            value={paramValue}
                            onChange={(e) => {
                              const newValue =
                                column.type === "number"
                                  ? e.target.value === ""
                                    ? ""
                                    : Number(e.target.value)
                                  : e.target.value;
                              handleParamChange(
                                user.userId,
                                column.id,
                                newValue
                              );
                            }}
                            size="small"
                            inputProps={
                              column.type === "number"
                                ? { min: column.min, max: column.max }
                                : {}
                            }
                            fullWidth
                          />
                        )
                      ) : paramValue !== undefined ? (
                        String(paramValue)
                      ) : (
                        ""
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton
        showLastButton
      />

      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default ManageParams;
