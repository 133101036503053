const courseServiceConfig = {
    allCourse: '/all-course',
    allLesson: '/all-lesson',
    course: '/course',
    lesson: '/lesson',
    lessonBatch: '/lesson-batch',
    uploadImageToCoursesBucket: '/upload-course-image',
    reorderCourse: '/course-reorder',
    fetchUserEnrollment: '/fetch-user-enrollment',
    createEnrollment: '/create-user-enrollment',
    updateUserEnrollment: '/update-user-enrollment'
};

export default courseServiceConfig;
