import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Grid,
  Chip,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import courseService from "../../services/courseService";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import AlertSnackbar from "../../components/AlertSnackbar";
import { formatDateToLongString } from "../../utils/constant";

const SortableItem = ({ course }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: course.courseId.toString() });
  const style = {
    transform: CSS.Transform?.toString(transform),
    transition,
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'published':
        return 'success';
      case 'draft':
        return 'warning';
      case 'archived':
        return 'default';
      default:
        return 'default';
    }
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      {...attributes}
      sx={{
        paddingTop: "12px",
        paddingBottom: "12px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box {...listeners} sx={{ cursor: "move", marginRight: 2 }}>
        <DragIndicatorIcon />
      </Box>
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography variant="subtitle1" component="div">
            {course.title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            #{course.order}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Chip 
            label={course.status} 
            size="small" 
            color={getStatusColor(course.status)}
          />
          <Typography variant="body2" fontWeight="bold">
            {course.isFree ? "Free" : "Paid -"}
          </Typography>
          {!course.isFree && (
            <Typography variant="body2" fontWeight="bold">
              ${course.price}
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            Created on: {formatDateToLongString(course.createdAt)}
          </Typography>
        </Box>
      </Box>
    </ListItem>
  );
};

const CourseReorderPage = () => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleSaveCourseOrder = async () => {
    setIsLoading(true);
    try {
      const respReorderCourse = await courseService.reorderCourse({
        courses: courses,
      });
      console.log(respReorderCourse);
      setSnackbar({
        open: true,
        message: "The courses have been reordered.",
        severity: "success",
      });
    } catch (error) {
      console.error("Error ordering the courses:", error);
      setSnackbar({
        open: true,
        message:
          error?.response?.data?.message ||
          "An error occurred while ordering the courses",
        severity: "error",
      });
    }    
    setIsLoading(false);
  };

  const fetchCourses = async () => {
    try {
      const resp = await courseService.getAllCourse();
      console.log(resp);
      setCourses(resp?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setCourses((items) => {
        const oldIndex = items.findIndex(
          (item) => item.courseId.toString() === active.id
        );
        const newIndex = items.findIndex(
          (item) => item.courseId.toString() === over.id
        );

        return arrayMove(items, oldIndex, newIndex).map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      });
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Typography variant="h1" gutterBottom>
        Reorder Courses
      </Typography>

      <Divider sx={{ marginBottom: "1em" }} />

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={courses?.map((c) => c.courseId.toString())}
                strategy={verticalListSortingStrategy}
              >
                <List sx={{ padding: 0 }}>
                  {courses?.map((course) => (
                    <SortableItem key={course.courseId} course={course} />
                  ))}
                </List>
              </SortableContext>
            </DndContext>
          </Box>
          <Divider />
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <Button
              variant="contained"
              onClick={handleSaveCourseOrder}
              className="w-full py-3 mt-6"
            >
              Save Courses Order
            </Button>
          </Box>
        </Paper>
      </Box>

      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CourseReorderPage;
