/*
 * Admin Layout
 */

import React from "react";
import { styled } from "@mui/system";
import HeaderComponent from "../components/HeaderComponent";
import SidebarComponent from "../components/SidebarComponent";
import '../styles/custom.css'

const Root = styled("div")({
  display: "flex",
});

const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: "54px", // AppBar height
  width: "calc(100% - 270px)",
}));

function AdminLayout({ children }) {
  return (
    <Root>
      <HeaderComponent />
      <SidebarComponent />
      <Main>{children}</Main>
    </Root>
  );
}

export default AdminLayout;