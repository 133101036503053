import React from "react";
import { Typography, Paper, Box, Divider } from "@mui/material";
import Chart from "react-apexcharts";

function AdminRatioChart({ userData }) {
  const chartOptions = {
    chart: { id: "admin-ratio" },
    labels: ["Admins", "Regular Users"],
  };

  const series = [
    userData.filter((user) => user.isAdmin).length,
    userData.filter((user) => !user.isAdmin).length,
  ];

  return (
    <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Typography variant="h2" component="h2" gutterBottom>
          Admin vs Regular Users
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Chart
          options={chartOptions}
          series={series}
          type="donut"
          width="100%"
        />
      </Box>
    </Paper>
  );
}

export default AdminRatioChart;
