import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  TextField,
  Button,
  Chip,
  Divider,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import courseService from "../../services/courseService";
import previewCourseDefaultImage from "../../asset/images/preview-course-default.jpeg";

const PriceRibbon = ({ price }) => (
  <Box
    sx={{
      position: "absolute",
      top: 16,
      right: -36,
      transform: "rotate(45deg)",
      backgroundColor: "primary.main",
      color: "white",
      padding: "6px 40px",
      fontWeight: "bold",
      zIndex: 1,
      boxShadow: "0 3px 10px rgba(0,0,0,0.3)", // Added shadow
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "100%",
        width: 0,
        height: 0,
      },
      "&::before": {
        left: 0,
        borderRight: "4px solid transparent",
        borderBottom: "4px solid transparent",
        borderLeft: "4px solid rgba(0,0,0,0.2)", // Shadow effect
      },
      "&::after": {
        right: 0,
        borderLeft: "4px solid transparent",
        borderBottom: "4px solid transparent",
        borderRight: "4px solid rgba(0,0,0,0.2)", // Shadow effect
      },
    }}
  >
    ${price}
  </Box>
);

const ManageCourses = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [visibleCourses, setVisibleCourses] = useState(9);
  const [courseList, setCourseList] = useState([]);
  const [publishedChecked, setPublishedChecked] = useState(true);
  const [draftChecked, setDraftChecked] = useState(true);
  const [archivedChecked, setArchivedChecked] = useState(false);
  const coursesPerLoad = 9;

  const navigate = useNavigate();

  let filteredCourses = courseList.filter((course) =>
    course.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const statusFilter = [];
  if (publishedChecked) {
    statusFilter.push("published");
  }
  if (draftChecked) {
    statusFilter.push("draft");
  }
  if (archivedChecked) {
    statusFilter.push("archived");
  }

  if (statusFilter.length > 0) {
    filteredCourses = filteredCourses.filter((course) =>
      statusFilter.includes(course.status)
    );
  }

  const currentCourses = filteredCourses.slice(0, visibleCourses);

  const handleLoadMore = () => {
    setVisibleCourses((prevVisible) => prevVisible + coursesPerLoad);
  };

  const handleCardClick = (courseId) => {
    navigate(`/courses/edit/${courseId}`);
  };

  const fetchCourses = async () => {
    try {
      const resp = await courseService.getAllCourse();
      console.log("Get all course: ", resp);
      setCourseList(resp?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <div>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography variant="h1" sx={{ flexGrow: 1 }}>
          Manage Courses
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => navigate("/courses/create")}
          sx={{ ml: 2 }}
        >
          Create New Course
        </Button>
      </Box>
      <Divider sx={{ marginBottom: "1em" }} />
      <Box
        sx={{
          my: 4,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextField
          placeholder="Search courses"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          size="small"
          sx={{ flexGrow: 1 }}
        />
        <FormGroup
          sx={{
            ml: 2,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={publishedChecked}
                onChange={(e) => setPublishedChecked(e.target.checked)}
                color="success"
              />
            }
            label="Published"
            sx={{ mr: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={draftChecked}
                onChange={(e) => setDraftChecked(e.target.checked)}
                color="warning"
              />
            }
            label="Draft"
            sx={{ mr: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={archivedChecked}
                onChange={(e) => setArchivedChecked(e.target.checked)}
                color="default"
              />
            }
            label="Archived"
            sx={{ mr: 2 }}
          />
        </FormGroup>
      </Box>
      <Grid container spacing={4}>
        {currentCourses.map((course) => (
          <Grid item key={course.courseId} xs={12} sm={6} md={4}>
            <Card
              sx={{
                height: "100%",
                display: "flex",
                flexDirection: "column",
                borderRadius: "12px",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: 3,
                },
                position: "relative",
                overflow: "hidden",
              }}
              variant="outlined"
              onClick={() => handleCardClick(course.courseId)}
            >
              {course.price > 0 && <PriceRibbon price={course.price} />}
              <CardMedia
                component="img"
                image={
                  course.thumbnailUrl
                    ? course.thumbnailUrl
                    : previewCourseDefaultImage
                }
                alt=""
                sx={{ height: "225px" }}
              />
              <CardContent
                sx={{
                  flexGrow: 1,
                  position: "relative",
                  padding: "1rem !important",
                }}
              >
                <Typography gutterBottom variant="h5" component="div">
                  {course.title}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  sx={{ mb: 2 }}
                >
                  <div
                    dangerouslySetInnerHTML={{ __html: course.description }}
                  />
                </Typography>
                <Chip
                  label={course.status}
                  color={
                    course.status === "published"
                      ? "success"
                      : course.status === "archived"
                      ? "default"
                      : "warning"
                  }
                  size="small"
                />
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      {visibleCourses < filteredCourses.length && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button variant="contained" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        </Box>
      )}
    </div>
  );
};

export default ManageCourses;
