import axios from "axios";
import paramServiceConfig from "./paramServiceConfig";

class paramService {
  getAllParamByFeature = (featureName) => axios.get(`${paramServiceConfig.getAllParamByFeature}/${featureName}`);
  addParams = (body, id) => axios.post(`${paramServiceConfig.addParams}/${id}`, body);
  getParamsByAdmin = (id=null) => axios.get(`${paramServiceConfig.getAllUserParams}/${id}`);
}

const instance = new paramService();

export default instance;
