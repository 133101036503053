import axios from "axios";
import courseServiceConfig from "./courseServiceConfig";

class courseService {
  getAllCourse = (params=null) => axios.get(`${courseServiceConfig.allCourse}`, {params});
  getCourseById = (id = null) => axios.get(`${courseServiceConfig.course}/${id}`);
  getAllLesson = (id = null) => axios.get(`${courseServiceConfig.allLesson}/${id}`);
  getLessonById = (id = null) => axios.get(`${courseServiceConfig.lesson}/${id}`);

  createCourse = (body) => axios.post(`${courseServiceConfig.course}`, body);
  addLesson = (body) => axios.post(`${courseServiceConfig.lesson}`, body);

  editCourse = (body, id) => axios.patch(`${courseServiceConfig.course}/${id}`, body);
  editLesson = (body, id) => axios.patch(`${courseServiceConfig.lesson}/${id}`, body);
  editLessonBatch = (body) => axios.patch(`${courseServiceConfig.lessonBatch}`, body);
  reorderCourse = (body) => axios.patch(`${courseServiceConfig.reorderCourse}`, body);

  deleteLesson = (id) => axios.delete(`${courseServiceConfig.lesson}/${id}`);

  saveCourseImage = (payload) => {
    return axios.post(
      `${courseServiceConfig.uploadImageToCoursesBucket}`, payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  };
  
  fetchUserEnrollment = (id = null) => axios.get(`${courseServiceConfig.fetchUserEnrollment}/${id}`);
  createEnrollment = (id, body) => axios.post(`${courseServiceConfig.createEnrollment}/${id}`, body);
  updateUserEnrollment = (body) => axios.patch(`${courseServiceConfig.updateUserEnrollment}`, body);
}

const instance = new courseService();

export default instance;