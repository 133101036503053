import React from "react";
import { InputLabel, Select, MenuItem, Box } from "@mui/material";

function SelectInputComponent({ label, value, onChange, options, ...props }) {
  return (
    <Box sx={{ mb: 2 }}>
      <InputLabel htmlFor={`${label}-select`}>{label}</InputLabel>
      <Select value={value} onChange={onChange} sx={{ height: "2.5rem" }} {...props}>
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}

export default SelectInputComponent;
