import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditIcon from "@mui/icons-material/Edit";
import MUITableComponent from "../../components/MUITableComponent";
import usersService from "../../services/usersService";
import courseService from "../../services/courseService";
import { capitalizeFirstLetter, paymentStatuses } from "../../utils/constant";
import AlertSnackbar from "../../components/AlertSnackbar";

const schema = yup.object().shape({
  courseId: yup.string().required("Course is required"),
  paymentStatus: yup.string().required("Payment status is required"),
  paymentAmount: yup
    .number()
    .required("Payment amount is required")
    .typeError("Payment amount must be a number")
    .positive("Amount must be positive"),
  status: yup
    .string()
    .oneOf(["active", "inactive"])
    .required("Enrollment status is required"),
});

function UserCourseEnrollmentPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [enrollments, setEnrollments] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [search, setSearch] = useState("");
  const [paymentStatusFilter, setPaymentStatusFilter] = useState("");
  const [editingEnrollment, setEditingEnrollment] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      courseId: "",
      paymentStatus: "",
      paymentAmount: "",
      status: "active",
    },
  });

  const watchCourseId = watch("courseId");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [userResponse, enrollmentsResponse, coursesResponse] =
          await Promise.all([
            usersService.getUserDataById(userId),
            courseService.fetchUserEnrollment(userId),
            courseService.getAllCourse({ status: "published" }),
          ]);
        setUser(userResponse?.data?.data);
        setEnrollments(enrollmentsResponse?.data?.data);
        setAllCourses(coursesResponse?.data?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [userId]);

  const availableCourses = useMemo(() => {
    return allCourses.filter(
      (course) =>
        course.isFree === false &&
        !enrollments.some(
          (enrollment) =>
            enrollment.courseId === course.courseId &&
            enrollment.paymentStatus === "completed"
        )
    );
  }, [allCourses, enrollments]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      if (editingEnrollment) {
        // Update existing enrollment
        await courseService.updateUserEnrollment({
          enrollmentId: editingEnrollment.enrollmentId,
          paymentStatus: data.paymentStatus,
          paymentAmount: data.paymentAmount,
          status: data.status,
        });
        setSnackbar({
          open: true,
          message: "The enrolled course has been updated successfully.",
          severity: "success",
        });
      } else {
        // Create new enrollment
        await courseService.createEnrollment(userId, {
          courseId: data.courseId,
          paymentStatus: data.paymentStatus,
          paymentAmount: data.paymentAmount,
        });
        setSnackbar({
          open: true,
          message: "User has been successfully enrolled in the course.",
          severity: "success",
        });
      }
      // Refresh enrollments
      const updatedEnrollments = await courseService.fetchUserEnrollment(
        userId
      );
      setEnrollments(updatedEnrollments?.data?.data);
    } catch (error) {
      console.error("Error submitting enrollment:", error);
      setSnackbar({
        open: true,
        message: error?.response?.data?.message,
        severity: "error",
      });
    } finally {
      setOpenDialog(false);
      setEditingEnrollment(null);
      reset();
      setIsLoading(false);
    }
  };

  const handleEditEnrollment = (enrollment) => {
    setEditingEnrollment(enrollment);
    setValue("courseId", enrollment.courseId);
    setValue("paymentStatus", enrollment.paymentStatus);
    setValue("paymentAmount", enrollment.paymentAmount);
    setValue("status", enrollment.status);
    setOpenDialog(true);
  };

  const columns = [
    { id: "title", label: "Course Name", sortable: true, width: "40%" },
    {
      id: "status",
      label: "Enrollment Status",
      sortable: true,
      width: "17%",
      render: (row) => capitalizeFirstLetter(row.status),
    },
    {
      id: "paymentStatus",
      label: "Payment Status",
      sortable: true,
      width: "17%",
      render: (row) => capitalizeFirstLetter(row.paymentStatus),
    },
    {
      id: "paymentAmount",
      label: "Payment Amount",
      sortable: true,
      width: "17%",
    },
    {
      id: "actions",
      label: "Action",
      sortable: false,
      width: "9%",
      render: (row) => (
        <IconButton onClick={() => handleEditEnrollment(row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const courseMap = useMemo(() => {
    return allCourses.reduce((acc, course) => {
      acc[course.courseId] = course;
      return acc;
    }, {});
  }, [allCourses]);

  const filteredEnrollments = useMemo(() => {
    setIsLoading(true);
    const filtered = enrollments
      .filter((enrollment) => {
        const course = courseMap[enrollment.courseId];
        return (
          (!search ||
            course?.title?.toLowerCase().includes(search.toLowerCase())) &&
          (paymentStatusFilter === "" ||
            enrollment.paymentStatus === paymentStatusFilter)
        );
      })
      .map((enrollment) => ({
        ...enrollment,
        title: courseMap[enrollment.courseId]?.title || "Unknown Course",
      }));
    setIsLoading(false);
    return filtered;
  }, [enrollments, courseMap, search, paymentStatusFilter]);

  const handleSearch = (e) => {
    setIsLoading(true);
    setSearch(e.target.value);
  };

  const handlePaymentStatusFilter = (e) => {
    setIsLoading(true);
    setPaymentStatusFilter(e.target.value);
  };

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Box>
      <Button
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/users")}
        sx={{ mb: 1 }}
      >
        Back to Users
      </Button>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h1" gutterBottom>
          Course Enrollment for {user?.firstName} {user?.lastName || ""}
        </Typography>
        <Button
          variant="contained"
          startIcon={<AssignmentIcon aria-hidden={true} />}
          onClick={() => setOpenDialog(true)}
          sx={{ ml: 2, mb: 1 }}
        >
          Enroll User in Course
        </Button>
      </Box>

      <Divider sx={{ mb: 3 }} />

      <Box sx={{ display: "flex", mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder="Search courses..."
          value={search}
          size="small"
          onChange={handleSearch}
          sx={{ mr: 2 }}
          disabled={isLoading}
        />
        <FormControl size="small" sx={{ minWidth: 200 }}>
          <InputLabel id="payment-status-filter-label">
            Payment Status
          </InputLabel>
          <Select
            labelId="payment-status-filter-label"
            value={paymentStatusFilter}
            label="Payment Status"
            onChange={handlePaymentStatusFilter}
            disabled={isLoading}
          >
            <MenuItem value="">All</MenuItem>
            {paymentStatuses.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      {filteredEnrollments.length === 0 ? (
        <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
          <Typography variant="body1">No records found</Typography>
        </Box>
      ) : (
        <MUITableComponent
          columns={columns}
          tableData={filteredEnrollments}
          hasActions={true}
        />
      )}

      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        disableEscapeKeyDown={true}
        maxWidth={"md"}
        fullWidth
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
          },
          "& td": {
            padding: "9px",
          },
        }}
        PaperProps={{ sx: { mt: "50px", borderRadius: "20px" } }}
      >
        <DialogTitle>
          {editingEnrollment ? "Edit Enrollment" : "Enroll User in Course"}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  name="courseId"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel htmlFor="courseName">
                        {"Course "}
                        <Typography color="error" component="span">
                          *
                        </Typography>
                      </InputLabel>
                      <TextField
                        {...field}
                        select
                        id="courseName"
                        fullWidth
                        error={!!errors.courseId}
                        helperText={errors.courseId?.message}
                        onChange={(e) => {
                          field.onChange(e);
                          const selectedCourse = availableCourses.find(
                            (course) => course.courseId === e.target.value
                          );
                          if (selectedCourse) {
                            setValue("paymentAmount", selectedCourse.price);
                          }
                        }}
                        size="small"
                        disabled={!!editingEnrollment}
                        sx={{
                          "& .Mui-error": { marginLeft: 0, marginRight: 0 },
                        }}
                      >
                        {(editingEnrollment
                          ? allCourses
                          : availableCourses
                        ).map((course) => (
                          <MenuItem
                            key={course.courseId}
                            value={course.courseId}
                          >
                            {course.title}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="paymentStatus"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel htmlFor="paymentStatus">
                        {"Payment Status "}
                        <Typography color="error" component="span">
                          *
                        </Typography>
                      </InputLabel>
                      <TextField
                        {...field}
                        select
                        fullWidth
                        id="paymentStatus"
                        error={!!errors.paymentStatus}
                        helperText={errors.paymentStatus?.message}
                        size="small"
                        sx={{
                          "& .Mui-error": { marginLeft: 0, marginRight: 0 },
                        }}
                      >
                        {paymentStatuses.map((status) => (
                          <MenuItem key={status.value} value={status.value}>
                            {status.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="paymentAmount"
                  control={control}
                  render={({ field }) => (
                    <>
                      <InputLabel htmlFor="paymentAmount">
                        {"Payment Amount "}
                        <Typography color="error" component="span">
                          *
                        </Typography>
                      </InputLabel>
                      <TextField
                        {...field}
                        type="number"
                        id="paymentAmount"
                        fullWidth
                        error={!!errors.paymentAmount}
                        helperText={errors.paymentAmount?.message}
                        size="small"
                        sx={{
                          "& .Mui-error": { marginLeft: 0, marginRight: 0 },
                        }}
                      />
                    </>
                  )}
                />
              </Grid>
              {editingEnrollment && (
                <Grid item xs={12}>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <>
                        <InputLabel htmlFor="status">
                          {"Enrollment Status "}
                          <Typography color="error" component="span">
                            *
                          </Typography>
                        </InputLabel>
                        <TextField
                          {...field}
                          select
                          id="status"
                          fullWidth
                          error={!!errors.status}
                          helperText={errors.status?.message}
                          size="small"
                          sx={{
                            "& .Mui-error": { marginLeft: 0, marginRight: 0 },
                          }}
                        >
                          <MenuItem value="active">Active</MenuItem>
                          <MenuItem value="inactive">Inactive</MenuItem>
                        </TextField>
                      </>
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setEditingEnrollment(null);
              reset();
            }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            color="primary"
          >
            {editingEnrollment ? "Update" : "Enroll"}
          </Button>
        </DialogActions>
      </Dialog>

      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />
    </Box>
  );
}

export default UserCourseEnrollmentPage;
