import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardPage from "../pages/dashboard/DashboardPage";
import ManageUsersPage from "../pages/users/ManageUsersPage";
import UserDetailsPage from "../pages/users/UserDetailsPage";
import CreateNewUserPage from "../pages/users/CreateNewUserPage";
import UserCourseEnrollmentPage from "../pages/users/UserCourseEnrollmentPage";
import ManageCoursesPage from "../pages/courses/ManageCoursesPage";
import CourseDetailsPage from "../pages/courses/CourseDetailsPage";
import CreateNewCoursePage from "../pages/courses/CreateNewCoursePage";
import CourseReorderPage from "../pages/courses/CourseReorderPage";
import ManageParamsPage from "../pages/params/ManageParamsPage";
import SignInPage from "../pages/SignInPage";
import NotFoundPage from "../pages/NotFoundPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import userService from '../services/usersService';

function AdminRoutes() {
  const isAuthenticated = userService.isAuthenticated();
  return (
    <Routes>
      <Route element={<PublicRoute />}>
        <Route path="/sign-in" element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <SignInPage />} />
      </Route>
      <Route element={<PrivateRoute />}>
        <Route path="/dashboard" element={<DashboardPage />} />
        <Route path="/users" element={<ManageUsersPage />} />
        <Route path="/users/edit/:userId" element={<UserDetailsPage />} />
        <Route path="/users/create" element={<CreateNewUserPage />} />
        <Route path="/users/:userId/course-enrollment" element={<UserCourseEnrollmentPage />} />
        <Route path="/courses" element={<ManageCoursesPage />} />
        <Route path="/courses/edit/:courseId" element={<CourseDetailsPage />} />
        <Route path="/courses/create" element={<CreateNewCoursePage />} />
        <Route path="/courses/order" element={<CourseReorderPage />} />
        <Route path="/params" element={<ManageParamsPage />} />
      </Route>
      <Route path="/" element={<Navigate to={isAuthenticated ? "/dashboard" : "/sign-in"} replace />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

export default AdminRoutes;
