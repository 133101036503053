/*
 * Material UI themes
 */

import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#4BA155",
      dark: "#1B5E20",
    },
  },
  typography: {
    fontFamily: "'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif",
    h1: {
      fontSize: "2.3em",
      fontWeight: 600,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          fontFamily:
            "'Inter', 'Roboto', 'Helvetica Neue', 'Arial', sans-serif",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "2em",
          fontWeight: 600,
        },
        h2: {
          fontSize: "1.3em",
          fontWeight: 600,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          borderRadius: "8px",
          "&.Mui-readOnly": {
            backgroundColor: "rgba(0, 0, 0, 0.04)", // Light grey background
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.23)", // Normal border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.23)", // No change on hover
            },
            "& input": {
              cursor: "default",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          color: "#161414",
          fontWeight: 500,
          paddingBottom: "3px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "14px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          "&:hover": {
            backgroundColor: "rgba(75, 161, 85, 0.08)", // 8% opacity of primary color
          },
          "&.Mui-selected": {
            backgroundColor: "rgba(75, 161, 85, 0.16)", // 16% opacity of primary color
            "&:hover": {
              backgroundColor: "rgba(75, 161, 85, 0.24)", // 24% opacity of primary color
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
