import { Editor } from "@tinymce/tinymce-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  FormControlLabel,
  Switch,
  CircularProgress,
  Backdrop,
  FormHelperText,
} from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import UploadIcon from "@mui/icons-material/Upload";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TextInputComponent from "../../components/TextInputComponent";
import SelectInputComponent from "../../components/SelectInputComponent";
import courseService from "../../services/courseService";
import AlertSnackbar from "../../components/AlertSnackbar";
import ConfirmationDialogComponent from "../../components/ConfirmationDialogComponent";
import {
  allowedExtensions,
  allowedTypes,
  generateUniqueIdentifier,
  maxSizeInBytes,
} from "../../utils/constant";

const schema = (isFree) =>
  yup.object().shape({
    title: yup.string().required("Course title is required"),
    image: yup
      .mixed()
      .test("fileSize", "File size exceeds 1 MB", (value) => {
        if (!value || !value[0]) return true; // No file selected, so no validation needed
        return value[0].size <= maxSizeInBytes;
      })
      .test("fileType", "Only JPEG and PNG files are allowed", (value) => {
        if (!value || !value[0]) return true; // No file selected, so no validation needed
        const fileType = value[0].type;
        const fileExtension = value[0].name.split('.').pop().toLowerCase();
        return allowedTypes.includes(fileType) && allowedExtensions.includes(fileExtension);
      }),
    price: isFree
      ? yup.number()
      : yup
          .number()
          .positive("Price must be a positive number")
          .required("Price is required"),
    stripeProductName: !isFree && yup.string().required("Stripe Product Name is required"),
  });

const SortableItem = ({ lesson, onDelete, onEdit, onCopy }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: lesson.lessonId.toString() });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <ListItem
      ref={setNodeRef}
      style={style}
      {...attributes}
      sx={{
        paddingTop: "12px",
        paddingBottom: "12px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box {...listeners} sx={{ marginRight: 2, cursor: "move" }}>
        <DragIndicatorIcon />
      </Box>
      <ListItemText
        primary={lesson.title}
        secondary={`Order: ${lesson.order}`}
      />
      <IconButton
        edge="end"
        aria-label="copy"
        onClick={() => onCopy(lesson)}
        sx={{ marginRight: 1 }}
        title="Duplicate"
      >
        <ContentCopyIcon />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="edit"
        onClick={() => onEdit(lesson)}
        sx={{ marginRight: 1 }}
        title="Edit"
      >
        <EditIcon />
      </IconButton>
      <IconButton
        edge="end"
        aria-label="delete"
        onClick={() => onDelete(lesson.lessonId)}
        title="Delete"
      >
        <DeleteIcon />
      </IconButton>
    </ListItem>
  );
};

const CourseDetailsPage = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [isAddingLesson, setIsAddingLesson] = useState(false);
  const [newLesson, setNewLesson] = useState({ title: "", content: "" });
  const [isFree, setIsFree] = useState(false);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [isEditingLesson, setIsEditingLesson] = useState(false);
  const [editingLesson, setEditingLesson] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [open, setOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imageFieldError, setImageFieldError] = useState("");
  const imageFileName = useMemo(() => generateUniqueIdentifier(), []);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const fetchCourse = async (parsedCourseId) => {
    try {
      const fetchedCourse = await courseService.getCourseById(parsedCourseId);
      console.log("Fetch Course ", fetchedCourse);
      const respFetchCourse = fetchedCourse.data.data;
      setCourse(respFetchCourse);
      setLessons(respFetchCourse.lessons);
      setNewLesson({ title: "", content: "", order: lessons.length + 1 });
      setIsFree(respFetchCourse.isFree === true);
      setThumbnailPreview(respFetchCourse.thumbnailUrl);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const parsedCourseId = parseInt(courseId);
    fetchCourse(parsedCourseId);
  }, [courseId, lessons.length]);

  const { control, handleSubmit, formState: { errors }, setValue } = useForm({
    resolver: yupResolver(schema(course?.isFree || false)),
    defaultValues: {
      title: course?.title || "",
      image: "",
      description: course?.description || "",
      isFree: course?.isFree || false,
      price: course?.price || 0,
      status: course?.status || "draft",
      thumbnailUrl: course?.thumbnailUrl || "",
      stripeProductName: course?.stripeProductName || null,
    },
  });

  useEffect(() => {
    if (course) {
      setValue("title", course.title);
      setValue("description", course.description);
      setValue("isFree", course.isFree);
      setValue("price", course.price);
      setValue("status", course.status);
      setValue("thumbnailUrl", course.thumbnailUrl);
      setValue("stripeProductName", course.stripeProductName);
    }
  }, [course, setValue]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCourse((prev) => ({ ...prev, [name]: value }));
  };

  const handleSwitchChange = (e) => {
    setIsFree(e.target.checked);
    setCourse((prev) => ({
      ...prev,
      isFree: e.target.checked,
      price: e.target.checked ? 0 : prev.price,
    }));
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setValue("image", e.target.files);
  };

  const handleFileUpload = async (selectedFile) => {
    if (!selectedFile) return null;

    if (selectedFile.size > maxSizeInBytes) {
      setImageFieldError("File size exceeds 1 MB.");
      return null;
    }

    // File type and extension check
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();
    if (
      !allowedTypes.includes(selectedFile.type) ||
      !allowedExtensions.includes(fileExtension)
    ) {
      console.error(
        "Error: File type not allowed. Only JPEG and PNG are allowed."
      );
      setImageFieldError("File type not allowed. Only JPEG and PNG are allowed.");
      return null;
    }

    setImageFieldError("");
    const formData = new FormData();
    const ext = selectedFile.name.split(".");
    const imageName = ext.length ? `${imageFileName}.${ext[ext.length - 1]}` : imageFileName;
    const newFile = new File([selectedFile], imageName, {
      type: selectedFile.type,
      lastModified: selectedFile.lastModified,
    });
    formData.append("fileUpload", newFile);

    try {
      const response = await courseService.saveCourseImage(formData);
      return { data: response?.data?.data, error: null };
    } catch (error) {
      console.error("Error uploading file:", error);
      return { data: null, error: error };
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      let imageUrl = course.thumbnailUrl;
      if (selectedFile) {
        const uploadResult = await handleFileUpload(selectedFile);
        if (uploadResult?.error) {
          setImageFieldError("Error in image uploading.");
          setIsLoading(false);
          return;
        }
        imageUrl = uploadResult?.data;
      }

      const updatedCourse = {
        ...course,
        title: data.title,
        description: data.description,
        isFree: isFree,
        price: isFree ? 0 : data.price,
        stripeProductName: data.stripeProductName === "" ? null : data.stripeProductName,
        status: data.status,
        thumbnailUrl: imageUrl,
      };

      const respUpdateCourse = await courseService.editCourse(updatedCourse, courseId);
      setCourse(respUpdateCourse.data.data);
      setSnackbar({
        open: true,
        message: "The course has been updated.",
        severity: "success",
      });
    } catch (error) {
      console.log("error while updating course ", error);
      setSnackbar({
        open: true,
        message: "Error updating course.",
        severity: "error",
      });
    }
    setIsLoading(false);
  };

const handleSaveLessons = async () => {
  setIsLoading(true);
  try {
    const respUpdateLessons = await courseService.editLessonBatch({ lessons });
    console.log("resp Update Lesson ", respUpdateLessons);
    setSnackbar({
      open: true,
      message: "The lesson(s) has been updated.",
      severity: "success",
    });
  } catch (error) {
    console.log("error while updating lessons ", error);
    setSnackbar({
      open: true,
      message: "Error updating lessons.",
      severity: "error",
    });
  }
  setIsLoading(false);
};

  const handleAddLesson = () => {
    setIsAddingLesson(true);
  };

  const handleCopyLesson = async (lessonToCopy) => {
    setIsLoading(true);
    try {
      const newLesson = {
        ...lessonToCopy,
        lessonId: null,
        title: `${lessonToCopy.title} (Copy)`,
        order: lessons.length + 1,
      };
      
      const respAddLesson = await courseService.addLesson({
        lessons: [newLesson],
      });
      
      const createdLesson = respAddLesson.data.data[0];
      setLessons(prevLessons => [...prevLessons, createdLesson]);
      setSnackbar({
        open: true,
        message: "The lesson has been copied.",
        severity: "success",
      });
    } catch (error) {
      console.log("error while copying lesson ", error);
      setSnackbar({
        open: true,
        message: "Error copying lesson.",
        severity: "error",
      });
    }
    setIsLoading(false);
  };

  const handleSaveNewLesson = async () => {
    setIsLoading(true);
    try {
      const objLesson = {
        title: newLesson.title,
        content: newLesson.content,
        order: lessons.length + 1,
        videoUrl: newLesson.videoUrl,
        courseId: courseId,
      };
      console.log(objLesson);
      const respAddLesson = await courseService.addLesson({
        lessons: [objLesson],
      });
      console.log("resp Add Lesson ", respAddLesson);
      const createdLesson = respAddLesson.data.data[0];
      setLessons(prevLessons => [...prevLessons, createdLesson]);
      setSnackbar({
        open: true,
        message: "The lesson has been added.",
      });
    } catch (error) {
      console.log("error while adding lesson ", error);
      setSnackbar({
        open: true,
        message: "Error adding lesson.",
        severity: "error",
      });
    }
    setNewLesson({ title: "", content: "" });
    setIsAddingLesson(false);
    setIsLoading(false);
  };

  const handleDeleteLesson = async () => {
    setIsLoading(true);
    try {
      const respLessonDelete = await courseService.deleteLesson(itemToDelete);
      console.log("resp Delete Lesson ", respLessonDelete);
      setLessons(prevLessons => prevLessons.filter(lesson => lesson.lessonId !== itemToDelete));
      setSnackbar({
        open: true,
        message: "The lesson has been deleted.",
      });
    } catch (error) {
      console.log("error while deleting lesson ", error);
      setSnackbar({
        open: true,
        message: "Error deleting lesson.",
        severity: "error",
      });
    }
    setIsLoading(false);
    setItemToDelete(null);
  };

  const handleConfirm = () => {
    handleDeleteLesson();
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setLessons((items) => {
        const oldIndex = items.findIndex(
          (item) => item.lessonId.toString() === active.id
        );
        const newIndex = items.findIndex(
          (item) => item.lessonId.toString() === over.id
        );

        return arrayMove(items, oldIndex, newIndex).map((item, index) => ({
          ...item,
          order: index + 1,
        }));
      });
    }
  };

  if (!course) return <Typography>Loading...</Typography>;

  const handleEditLesson = (lesson) => {
    setEditingLesson(lesson);
    setIsEditingLesson(true);
  };

  const handleSaveEditedLesson = () => {
    setIsLoading(true);
    setLessons((prev) =>
      prev.map((lesson) =>
        lesson.lessonId === editingLesson.lessonId ? editingLesson : lesson
      )
    );
    setIsEditingLesson(false);
    setEditingLesson(null);
    setIsLoading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          onClick={() => navigate("/courses")}
          aria-label="go back"
          title="Go Back"
          sx={{ marginBottom: "0.35em", marginRight: "0.1em" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1" gutterBottom>
          Edit Course
        </Typography>
      </Box>

      <Divider sx={{ marginBottom: "1em" }} />

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ mb: 3, borderRadius: "8px" }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <Controller
                    name="title"
                    control={control}
                    render={({ field }) => (
                      <TextInputComponent
                        {...field}
                        label="Course Title"
                        error={errors.title?.message}
                        required={true}
                      />
                    )}
                  />
                  <Typography
                    variant="subtitle1"
                    sx={{ mt: 2, fontSize: "14px", fontWeight: "500" }}
                  >
                    Description
                  </Typography>
                  <Editor
                    tinymceScriptSrc="/tinymce/tinymce.min.js"
                    value={course?.description || ""}
                    id="fancy-text-tool-area"
                    name="description"
                    init={{
                      height: 200,
                      entity_encoding: "raw",
                      license_key: "gpl",
                      plugins: [
                        "autolink",
                        "lists",
                        "link",
                        "charmap",
                        "preview",
                        "searchreplace",
                        "fullscreen",
                        "media",
                        "table",
                        "code",
                        "emoticons",
                      ],
                      toolbar:
                        "undo redo | " +
                        "formatselect | " +
                        "bold italic underline strikethrough | " +
                        "alignleft aligncenter alignright | " +
                        "bullist numlist | " +
                        "link emoticons | " +
                        "code removeformat",
                      emoticons_append: {
                        custom_mind_explode: {
                          keywords: ["brain", "mind", "explode", "blown"],
                          char: "🤯",
                        },
                      },
                      branding: false,
                      statusbar: false,
                      menubar: false,
                      content_style: "p { margin: 0}",
                    }}
                    onEditorChange={(newValue, editor) => {
                      setCourse((prev) => ({ ...prev, description: newValue }));
                    }}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={isFree}
                        onChange={handleSwitchChange}
                        name="isFree"
                        // disabled={true}
                      />
                    }
                    label="Free Course"
                    sx={{ mb: 1, mt: 1 }}
                  />
                  {!isFree && (
                    <>
                    <Controller
                      name="price"
                      control={control}
                      render={({ field }) => (
                        <TextInputComponent
                          {...field}
                          label="Price"
                          type="number"
                          error={errors.price?.message}
                          required={!isFree}
                        />
                      )}
                    />
                    <Controller
                      name="stripeProductName"
                      control={control}
                      render={({ field }) => (
                        <TextInputComponent
                          {...field}
                          label="Stripe Product Name"
                          error={errors.stripeProductName?.message}
                          required={!isFree}
                        />
                      )}
                    />
                  </>
                  )}
                  <SelectInputComponent
                    labelId="status-label"
                    name="status"
                    label="Status"
                    value={course?.status || "draft"}
                    onChange={handleInputChange}
                    options={[
                      {
                        label: "Draft",
                        value: "draft",
                      },
                      {
                        label: "Published",
                        value: "published",
                      },
                      {
                        label: "Archived",
                        value: "archived",
                      },
                    ]}
                    fullWidth
                    required
                  />

                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="thumbnail-upload"
                    type="file"
                    onChange={handleThumbnailChange}
                  />
                  <label htmlFor="thumbnail-upload">
                    <Button
                      variant="outlined"
                      component="span"
                      startIcon={<UploadIcon />}
                      sx={{ mb: 1 }}
                    >
                      Upload Thumbnail
                    </Button>
                  </label>
                  {errors.image && (
                    <FormHelperText error>
                      {errors.image.message}
                    </FormHelperText>
                  )}
                  {imageFieldError && (
                    <FormHelperText error sx={{ marginLeft: 0 }}>
                      {imageFieldError}
                    </FormHelperText>
                  )}
                  <FormHelperText
                    sx={{
                      marginLeft: 0,
                      fontStyle: "italic",
                      fontWeight: 600,
                      fontSize: "0.77rem",
                      letterSpacing: 0,
                    }}
                  >
                    [Max file size is 1 MB. Only JPEG (.jpg, .jpeg) and PNG (.png) file formats are allowed.]
                  </FormHelperText>
                </Grid>
                <Grid item xs={12} md={4}>
                  {thumbnailPreview && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" gutterBottom>
                        Thumbnail Preview
                      </Typography>
                      <img
                        src={thumbnailPreview}
                        alt="Course thumbnail"
                        style={{ maxWidth: "100%", maxHeight: "300px" }}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Button
                type="submit"
                variant="contained"
                className="w-full py-3 mt-6"
                disabled={isLoading}
              >
                {isLoading ? "Saving..." : "Save Course Changes"}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
          <Box
            sx={{
              padding: "0.8rem 1.2rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2" gutterBottom>
              Lessons
            </Typography>
            <Button startIcon={<AddIcon />} onClick={handleAddLesson}>
              Add Lesson
            </Button>
          </Box>
          <Divider />
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={lessons.map((l) => l.lessonId.toString())}
                strategy={verticalListSortingStrategy}
              >
                <List sx={{ padding: 0 }}>
                  {lessons.map((lesson) => (
                    <SortableItem
                      key={lesson.lessonId}
                      lesson={lesson}
                      onDelete={() => {
                        setItemToDelete(lesson.lessonId);
                        setOpen(true);
                      }}
                      onEdit={handleEditLesson}
                      onCopy={handleCopyLesson}
                    />
                  ))}
                </List>
              </SortableContext>
            </DndContext>
          </Box>
          <Divider />
          <Box sx={{ padding: "0.8rem 1.2rem" }}>
            <Button
              variant="contained"
              onClick={handleSaveLessons}
              className="w-full py-3 mt-6"
            >
              Save Lessons Changes
            </Button>
          </Box>
        </Paper>
      </Box>

      <Dialog
        open={isAddingLesson}
        onClose={() => setIsAddingLesson(false)}
        disableEscapeKeyDown={true}
        maxWidth={"md"}
        fullWidth
        sx={{ "& .MuiDialog-container": { alignItems: "flex-start" } }}
        PaperProps={{ sx: { mt: "50px", borderRadius: "20px" } }}
      >
        <DialogTitle>Add New Lesson</DialogTitle>
        <Divider />
        <DialogContent sx={{ paddingBottom: "0" }}>
          <TextInputComponent
            name="title"
            label="Lesson Title"
            value={newLesson.title}
            onChange={(e) =>
              setNewLesson((prev) => ({ ...prev, title: e.target.value }))
            }
            fullWidth
            required={true}
          />
          <TextInputComponent
            name="videoUrl"
            label="Video URL"
            value={newLesson.videoUrl}
            onChange={(e) =>
              setNewLesson((prev) => ({ ...prev, videoUrl: e.target.value }))
            }
            fullWidth
          />
          <Typography
            variant="subtitle1"
            sx={{ mt: 2, fontSize: "14px", fontWeight: "500" }}
          >
            Lesson Content / Notes
          </Typography>
          <Box sx={{ mb: 2 }}>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            value={newLesson.content}
            id="fancy-text-tool-area-add"
            name="content"
            init={{
              height: 200,
              entity_encoding: "raw",
              license_key: "gpl",
              plugins: [
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "searchreplace",
                "fullscreen",
                "media",
                "table",
                "code",
                "emoticons",
              ],
              toolbar:
                "undo redo | " +
                "formatselect | " +
                "bold italic underline strikethrough | " +
                "alignleft aligncenter alignright | " +
                "bullist numlist | " +
                "link emoticons | " +
                "code removeformat",
              emoticons_append: {
                custom_mind_explode: {
                  keywords: ["brain", "mind", "explode", "blown"],
                  char: "🤯",
                },
              },
              branding: false,
              statusbar: false,
              menubar: false,
              content_style: "p { margin: 0}",
            }}
            onEditorChange={(newValue, editor) => {
              setNewLesson((prev) => ({ ...prev, content: newValue }));
            }}
          />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button variant="outlined" onClick={() => setIsAddingLesson(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveNewLesson}
            disabled={!newLesson?.title?.trim() || isLoading}
          >
            Save Lesson
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isEditingLesson}
        onClose={() => setIsEditingLesson(false)}
        disableEscapeKeyDown={true}
        maxWidth={"md"}
        fullWidth
        sx={{ "& .MuiDialog-container": { alignItems: "flex-start" } }}
        PaperProps={{ sx: { mt: "50px", borderRadius: "20px" } }}
      >
        <DialogTitle>Edit Lesson</DialogTitle>
        <Divider />
        <DialogContent sx={{ paddingBottom: "0" }}>
          <TextInputComponent
            name="title"
            label="Lesson Title"
            value={editingLesson?.title || ""}
            onChange={(e) =>
              setEditingLesson((prev) => ({ ...prev, title: e.target.value }))
            }
            fullWidth
            required={true}
          />
          <TextInputComponent
            name="videoUrl"
            label="Video URL"
            value={editingLesson?.videoUrl || ""}
            onChange={(e) =>
              setEditingLesson((prev) => ({
                ...prev,
                videoUrl: e.target.value,
              }))
            }
            fullWidth
          />
          <Typography
            variant="subtitle1"
            sx={{ mt: 2, fontSize: "14px", fontWeight: "500" }}
          >
            Lesson Content / Notes
          </Typography>
          <Box sx={{ mb: 2 }}>
          <Editor
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            value={editingLesson?.content || ""}
            id="fancy-text-tool-area-edit"
            name="content"
            init={{
              height: 200,
              entity_encoding: "raw",
              license_key: "gpl",
              plugins: [
                "autolink",
                "lists",
                "link",
                "charmap",
                "preview",
                "searchreplace",
                "fullscreen",
                "media",
                "table",
                "code",
                "emoticons",
              ],
              toolbar:
                "undo redo | " +
                "formatselect | " +
                "bold italic underline strikethrough | " +
                "alignleft aligncenter alignright | " +
                "bullist numlist | " +
                "link emoticons | " +
                "code removeformat",
              emoticons_append: {
                custom_mind_explode: {
                  keywords: ["brain", "mind", "explode", "blown"],
                  char: "🤯",
                },
              },
              branding: false,
              statusbar: false,
              menubar: false,
              content_style: "p { margin: 0}",
            }}
            onEditorChange={(newValue, editor) => {
              setEditingLesson((prev) => ({ ...prev, content: newValue }));
            }}
          />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "16px 24px" }}>
          <Button variant="outlined" onClick={() => setIsEditingLesson(false)}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveEditedLesson}
            disabled={!editingLesson?.title?.trim() || isLoading}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>

      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      <ConfirmationDialogComponent
        open={open}
        title="Confirm Deletion"
        message={`Are you sure you want to delete?`}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CourseDetailsPage;
