import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Button,
  Box,
  Typography,
  Divider,
  Paper,
  IconButton,
  Checkbox,
  FormControlLabel,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextInputComponent from "../../components/TextInputComponent";
import AlertSnackbar from "../../components/AlertSnackbar";
import { extractFacebookUsername } from "../../utils/constant";
import usersService from "../../services/usersService";

const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string(),
  email: yup.string().email("Invalid email").required("Email is required"),
  createFacebookUser: yup.boolean(),
  fbUrl: yup.string().when("createFacebookUser", {
    is: true,
    then: () =>
      yup
        .string()
        .url("Invalid URL")
        .required("Facebook Profile URL is required"),
    otherwise: () => yup.string().notRequired(),
  }),
  password: yup.string().when("createFacebookUser", {
    is: true,
    then: () =>
      yup
        .string()
        .min(8, "Password must be at least 8 characters")
        .required("Password is required"),
    otherwise: () => yup.string().notRequired(),
  }),
  confirmPassword: yup.string().when("createFacebookUser", {
    is: true,
    then: () =>
      yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
    otherwise: () => yup.string().notRequired(),
  }),
});

const CreateNewUserPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
    defaultValues: {
      createFacebookUser: false,
    },
  });

  const createFacebookUser = watch("createFacebookUser");

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const {
        createFacebookUser,
        fbUrl,
        password,
        confirmPassword,
        ...userData
      } = data;

      // Prepare data for createUser API
      const createUserData = {
        firstName: userData.firstName,
        email: userData.email,
        ...(userData.lastName && { lastName: userData.lastName }), // Include lastName only if it's provided
      };

      // Create user
      const createUserResponse = await usersService.createUser(createUserData);

      if (createFacebookUser) {
        if (createUserResponse.status === 200) {
          const facebookUserName = extractFacebookUsername(fbUrl);
          const facebookUserData = {
            email: userData.email,
            facebookUserName,
            password,
          };

          // Create Facebook user
          await usersService.createFacebookUser(facebookUserData);
        }
      }

      setSnackbar({
        open: true,
        message: "User has been created successfully!",
        severity: "success",
      });
      navigate("/users");
    } catch (error) {
      console.error("Error while creating user:", error);
      setSnackbar({
        open: true,
        message: error.response?.data?.message || "Error creating user.",
        severity: "error",
      });
    }
    setIsLoading(false);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <IconButton
          onClick={() => navigate("/users")}
          aria-label="go back"
          title="Go Back"
          sx={{ marginBottom: "0.35em", marginRight: "0.1em" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1" gutterBottom>
          Create New User
        </Typography>
      </Box>
      <Divider sx={{ marginBottom: "1em" }} />

      <Box sx={{ margin: "auto", marginBottom: "1em" }}>
        <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextInputComponent
                      {...field}
                      label="First Name"
                      error={error?.message}
                      required={true}
                    />
                  )}
                />
                <Box sx={{ paddingTop: "4px" }}>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextInputComponent
                        {...field}
                        label="Last Name"
                        error={error?.message}
                      />
                    )}
                  />
                </Box>
              </div>
              <Controller
                name="email"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextInputComponent
                    {...field}
                    label="Email"
                    type="email"
                    error={error?.message}
                    required={true}
                  />
                )}
              />
              <Controller
                name="createFacebookUser"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={<Checkbox {...field} checked={field.value} />}
                    label="Create Facebook User"
                  />
                )}
              />
              {createFacebookUser && (
                <>
                  <Box sx={{ mt: 2 }}>
                    <Controller
                      name="fbUrl"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextInputComponent
                          {...field}
                          label="Facebook Profile URL"
                          error={error?.message}
                          required={true}
                        />
                      )}
                    />
                  </Box>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    <Controller
                      name="password"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextInputComponent
                          {...field}
                          label="Password"
                          type="password"
                          error={error?.message}
                          required={true}
                        />
                      )}
                    />
                    <Controller
                      name="confirmPassword"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <TextInputComponent
                          {...field}
                          label="Confirm Password"
                          type="password"
                          error={error?.message}
                          required={true}
                        />
                      )}
                    />
                  </div>
                </>
              )}
            </Box>
            <Divider />
            <Box sx={{ padding: "0.8rem 1.2rem" }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="w-full py-3 mt-6"
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : "Create User"}
              </Button>
            </Box>
          </form>
        </Paper>
      </Box>

      <AlertSnackbar
        open={snackbar.open}
        handleClose={handleCloseSnackbar}
        message={snackbar.message}
        severity={snackbar.severity}
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default CreateNewUserPage;
