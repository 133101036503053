import React from "react";
import { Typography, Paper, Divider, Box } from "@mui/material";
import Chart from "react-apexcharts";

function UserActivityChart({ userData }) {
  // Assuming you have a 'lastActiveAt' field. If not, use 'createdAt' or another relevant field
  const activityData = userData.reduce((acc, user) => {
    const date = user.lastActiveAt || user.createdAt;
    if (date && !isNaN(new Date(date).getTime())) {
      const formattedDate = new Date(date).toISOString().split("T")[0];
      acc[formattedDate] = (acc[formattedDate] || 0) + 1;
    }
    return acc;
  }, {});

  const chartData = Object.entries(activityData).map(([date, count]) => ({
    x: new Date(date).getTime(),
    y: count,
  }));

  const chartOptions = {
    chart: { id: "user-activity", type: "bar" },
    xaxis: { type: "datetime" },
    yaxis: { title: { text: "Active Users" } },
    title: { text: "Daily Active Users" },
  };

  const series = [
    {
      name: "Active Users",
      data: chartData,
    },
  ];

  return (
    <Paper variant="outlined" sx={{ borderRadius: "8px" }}>
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        <Typography variant="h2" component="h2" gutterBottom>
          User Activity
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ padding: "0.8rem 1.2rem" }}>
        {chartData.length > 0 ? (
          <Chart
            options={chartOptions}
            series={series}
            type="bar"
            height={300}
          />
        ) : (
          <Typography>No valid activity data available</Typography>
        )}
      </Box>
    </Paper>
  );
}

export default UserActivityChart;
